<template>
  <div class="">
    <!-- Loading -->
    <div class="cover" v-if="loadFlag == true">
      <van-loading class="loading" type="spinner" size="48px" />
      <div class="load-text">加载中...</div>
    </div>
    <navbar title="签到"></navbar>
    <div class="box">
      <!-- 大标题 -->
      <div class="title">
        <div class="title-detail">{{ title }}</div>
      </div>
      <!-- 分割线 -->
      <div class="line"></div>
      <!-- 签到部分 -->
      <div class="sign">
        <img @click="sign()" src="@/assets/img/sign-big.png" alt="" />
        <div>签到</div>
      </div>
    </div>
    <tabbar></tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import tabbar from "@/components/tabBar.vue";
import navbar from "@/components/navBar.vue";
import { userSignin, selectUserSigninThatDay, edit, userSigninLoad, getWxConfigs } from "@/api/api.js";
import { Toast, Dialog } from "vant";
import wx from 'weixin-js-sdk'

export default {
  name: "Home",
  components: {
    tabbar,
    navbar,
  },
  data() {
    return {
      // 覆盖签到用的 修改ID
      modifyId: "",
      title: "",
      timestamp: "",
      nonceStr: "",
      signature: "",
      jsapi_ticket: "",
      loadFlag: false,
      num: 0,
      // 签到按钮是否可点击的旗标
      flag: true,
    };
  },
  mounted() {
    if (this.$route.query.from == "home") {
      this.getWxConfig()
    }
    // 截取本地url上的ID（activityId）
    this.interceptUrl()
  },
  methods: {
    getWxConfig() {
      this.loadFlag = true
      let purl = /(Android)/i.test(navigator.userAgent) ? encodeURIComponent(location.href.split('#')[0]) : window.localStorage.getItem('scanUrl');
      // const u = navigator.userAgent;
      // const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      // if (isiOS && this.num==0) {
      //   location.reload();
      //   this.num ++
      // }
      // alert(purl)
      // 生成调用扫一扫需要的签名
      getWxConfigs({
        appid: this.WX_ID,
        appsecret: this.WX_SECRET,
        // url:encodeURIComponent(location.href.split("#")[0])
        // url:decodeURIComponent(location.href.split("#")[0])
        url: purl
      }).then((res) => {
        if (res.data.code == 0) {
          this.timestamp = res.data.data.timestamp
          this.nonceStr = res.data.data.nonceStr
          this.signature = res.data.data.signature
          this.jsapi_ticket = res.data.data.jsapi_ticket
          this.scan()
        } else {
          Toast(res.data.msg)
          this.loadFlag = false
        }
      })
    },
    scan() {
      wx.config({
        // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        debug: false,
        // 必填，公众号的唯一标识
        appId: this.WX_ID,
        // 必填，生成签名的时间戳
        timestamp: this.timestamp,
        // 必填，生成签名的随机串
        nonceStr: this.nonceStr,
        // 必填，签名
        signature: this.signature,
        // 必填，需要使用的JS接口列表，所有JS接口列表
        jsApiList: ["checkJsApi",
          "onMenuShareTimeline",
          "onMenuShareAppMessage",
          "onMenuShareQQ",
          "onMenuShareWeibo",
          "hideMenuItems",
          "showMenuItems",
          "hideAllNonBaseMenuItem",
          "showAllNonBaseMenuItem",
          "translateVoice",
          "startRecord",
          "stopRecord",
          "onRecordEnd",
          "playVoice",
          "pauseVoice",
          "stopVoice",
          "uploadVoice",
          "downloadVoice",
          "chooseImage",
          "previewImage",
          "uploadImage",
          "downloadImage",
          "getNetworkType",
          "openLocation",
          "getLocation",
          "hideOptionMenu",
          "showOptionMenu",
          "closeWindow",
          "scanQRCode",
          "chooseWXPay",
          "openProductSpecificView",
          "addCard",
          "chooseCard",
          "openCard"]
        // jsapi_ticket:this.jsapi_ticket
      });

      wx.error(function (res) {
        alert("出错了：" + res.errMsg);//这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
      });

      wx.ready(function () {
        this.loadFlag = false
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: function (res) {
            var result = res.resultStr
            window.location.replace(result)
            // _this.searchResults();
          },
          cancel: function () {
            window.location.replace(localStorage.getItem('scanUrl'))
          }
        });

      });
    },
    // 截取本地url上的ID（activityId）
    interceptUrl() {
      // 截取url中的code方法
      var url = window.location.href;
      // var url = "http://localhost:8082/sign?activityId=98922507100225926&deviceCode=11111&userId=3"
      this.winUrl = url;
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var s = str.split("=");
        var strs = s[2].split("&");
        // deviceCode放入缓存
        localStorage.setItem('deviceCode', strs[0]);
        var strs2 = s[1].split("&");
        // activityId放入缓存
        localStorage.setItem('activityId', strs2[0]);
        // userId放入缓存
        var strs3 = s[3].split("&");
        localStorage.setItem('userId', strs3[0]);
      }
      // 查询活动详情接口，获取活动名称
      this.getActivity()
    },
    // 查询活动详情接口
    getActivity() {
      userSigninLoad({
        id: localStorage.getItem('activityId')
      }).then((res) => {
        if (res.data.code == 0) {
          this.title = res.data.data.name
        } else {
          Toast(res.data.msg)
          if (res.data.msg == "当前签到已结束！") {
            this.title = res.data.msg
            this.flag = false
          }
        }
      })
    },
    // 签到
    sign() {
      // 先走查询当天是否签到接口
      selectUserSigninThatDay({
        // TODO
        activityId: localStorage.getItem("activityId"),
        deviceCode: localStorage.getItem("deviceCode"),
        openId: localStorage.getItem("openId"),
      }).then((res) => {
        if (res.data.code == 0) {
          if (localStorage.getItem("nickname") == undefined || localStorage.getItem("nickname") == null
            || localStorage.getItem("headImgUrl") == undefined || localStorage.getItem("headImgUrl") == null
            || localStorage.getItem("openId") == undefined || localStorage.getItem("openId") == null || localStorage.getItem("openId") == "undefined") {
            Toast('未获取到微信头像或昵称，请重新登录')
            return
          }
          if (
            res.data.data.id == null ||
            res.data.data.id == "" ||
            res.data.data.id == undefined
          ) {
            // 如果是id为空，代表今天没签到，走签到接口
            let obj = {
              activityId: localStorage.getItem("activityId"),
              createTime: "",
              createUser: 0,
              deviceCode: localStorage.getItem("deviceCode"),
              // id: 0,
              openId: localStorage.getItem("openId"),
              signInTime: "",
              tenantCode: "",
              updateTime: "",
              updateUser: 0,
              wxAvatarUrl: localStorage.getItem("headImgUrl"),
              wxName: localStorage.getItem("nickname"),
              userId: localStorage.getItem("userId"),
            };
            userSignin(obj).then((res) => {
              if (res.data.code == 0) {
                Toast("签到成功！");
                setTimeout(this.$router.push({ path: '/' }), 1000);
              } else {
                Toast(res.data.msg);
              }
            });
          } else {
            // 获取修改ID，覆盖签到时使用
            this.modifyId = res.data.data.id
            // 否则代表今天已经签到，弹出提示
            Dialog.confirm({
              message: "今日已签到，是否覆盖之前签到信息？",
            })
              .then(() => {
                // on confirm ， 走覆盖签到接口
                edit({
                  // TODO
                  activityId: localStorage.getItem("activityId"),
                  id: this.modifyId,
                  openId: localStorage.getItem("openId"),
                  userId: localStorage.getItem("userId"),
                }).then((res) => {
                  if (res.data.code == 0) {
                    Toast('覆盖签到成功！')
                    setTimeout(this.$router.push({ path: '/' }), 1000);
                  } else {
                    Toast(res.data.msg)
                  }
                });
              })
              .catch(() => {
                // on cancel
              });
          }
        } else {
          Toast(res.data.msg)
        }
      });
    },

  },
};
</script>
<style scoped>
.title {
  width: 100%;
  height: 218px;
  position: relative;
}

.title-detail {
  width: 100%;
  text-align: center;
  position: absolute;
  color: #333333;
  font-size: 32px;
  font-family: Songti SC;
  font-weight: bold;
  top: 50%;
  transform: translateY(-50%);
}

.line {
  width: 100%;
  height: 20px;
  background: #faf9fb;
}

.sign {
  text-align: center;
}

.sign img {
  width: 300px;
  height: 300px;
  margin-top: 160px;
}

.sign div {
  margin-top: 40px;
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #505050;
}

.cover {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 999;
}

.loading,
.load-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.load-text {
  top: 50%;
  color: #fff;
  font-size: 35px;
}
</style>